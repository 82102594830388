// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-download-report-tsx": () => import("./../../../src/pages/download-report.tsx" /* webpackChunkName: "component---src-pages-download-report-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-introduction-tsx": () => import("./../../../src/pages/introduction.tsx" /* webpackChunkName: "component---src-pages-introduction-tsx" */),
  "component---src-pages-methodology-tsx": () => import("./../../../src/pages/methodology.tsx" /* webpackChunkName: "component---src-pages-methodology-tsx" */),
  "component---src-pages-top-50-tsx": () => import("./../../../src/pages/top-50.tsx" /* webpackChunkName: "component---src-pages-top-50-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/Company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */)
}

